// PRELOADER CLOSING

// Core variables and mixins
@import "bootstrap/functions";
@import "_variables_theme";
@import "_variables";
@import "bootstrap/_mixins";
@import "_mixins_theme";

//Reset and dependencies

// Reset and dependencies
@import "bootstrap/bootstrap";
@import "_fonts";
@import "elements/font-awesome";
//@import "elements/jquery-ui";

@import "elements/preload";

// Theme
@import "_custom";

@import "elements/navs";
@import "elements/covers";
@import "elements/lists";
@import "elements/main";
@import "elements/blocks";
@import "elements/fancybox";
@import "elements/cookiebar";

// TEXT CONTENT DEFINITION
.content {
@import "elements/content";
}
