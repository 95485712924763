#cookie-bar {
    background:rgba($black,0.8);
    height:auto;
    line-height:24px;
    color:$white;
    text-align:center;
    padding:15px;
    transform:translate3d(0,0,4px);
    &.fixed {
        position:fixed; top:0; left:0; width:100%;
    }
    &.fixed.bottom {
        bottom:0; top:auto;
    }
    p {
        margin:0; padding:0;
    }
    a {
        color:$white;
    }
 .cb-enable {
    @import "elements/content";
    @extend .btn;
    @extend .btn-sm;
    @extend  .btn-success;
     margin:0 5px 0 5px;
     margin-top:10px;
    }
 .cb-enable:hover {
    }
 .cb-disable {
    @extend .btn;
    @extend .btn-sm;
    @extend .btn-danger;
     margin:3px 5px 3px 5px;
     margin-top:10px;
     display:none;
    }
 .cb-disable:hover {
    }
 .cb-policy {
    @extend .btn;
    @extend .btn-sm;
     //.btn-info;
     background:none;
     margin:0 5px 0 5px;
     color:$white;
     //border:solid 1px $white;
     margin-top:10px;
    }
 .cb-policy:hover {
     background:none;
     color:$white;
     border:solid 1px $white;
    }
}
