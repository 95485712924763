

.block {
	display:block !important;
	width: 100%;
	position: relative;
	z-index: 10;
	min-height: 100vh;
	-webkit-animation:blockFade 2.5s normal forwards; animation:blockFade 2.5s normal forwards;
}

.phones-wrap {
	position: relative;
	height: 80vh;
	min-height:400px;
	.container, .container-fluid, .row {
		height:100%;
	}
	.phones {
		background-image: url('../images/phones.png');
	  background-size: cover;
	  background-position: right center;
		position: relative;
		height:100%;
	}
}

.features-wrap {
	position: relative;
	overflow:hidden;
	min-height:50vw;

	.hand {
	 	position: absolute;
		z-index: 50;
		top:0px;
		right:0px;
		width:50%;
		@include media-breakpoint-down(sm) {
			width:100%;
		}
	}
	.container {
		@include media-breakpoint-down(sm) {
			padding-top:80vw;
		}
		position: relative;
		z-index: 50;
	}
}


.footer {
	margin-top:400px;
}
.footer-phone {
	margin-top:-330px;
	width:300px;
	height:600px;
	background-image: url('../images/footer.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
	padding-top:350px;
	.link {
		text-align:center;
		width:100%;
		display:block;
		img {
			width:70%;
			margin-bottom:5px;
		}
	}
}


@keyframes blockFade {
		0% {
				opacity:0;
		}

		90% {
				opacity:0;
		}

		100% {
			opacity:1;
		}

}
