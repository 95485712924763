html, body {

 min-height:100vh;
 width:100vw;
 background-color: $white !important;

}
.bg-deep {
  background-color: $deep;
}
.text-deep {
  color: $deep;
}
.bg-gold {
  background-color: $gold;

}
.bg-gold-patern {
  background-color: $gold;
  background-image: url('../images/gold.png');
  background-position: center center;
  border-bottom: solid 2px $gold;
  border-top: solid 2px $gold;
}
.text-gold {
  color: $gold;
}
.bg-stone {
  background-color: $stone;
}
.text-stone {
  color: $stone;
}

.bg-white-patern {
  background-image: url('../images/white.jpg');
  background-size: cover;
  background-position: center center;
  border-bottom: solid 2px $gold;
  border-top: solid 2px $gold;
  h1, p, h2, h3, {
    text-shadow: 1px 1px 20px $white;
  }
}

.btn-outline-white {
	border:solid 1px $white;
	color:$white;
	&:hover {
		background-color: $white;
		color:$black;
	}
}
.btn-outline-black {
	border:solid 1px $black;
	color:$black;
	&:hover {
		background-color: $black;
		color:$white;
	}
}
strong {
	font-weight:700;
}

.invalid .form-control, .invalid.form-control, .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
	border-color: $danger;
  color:$danger;
  &::placeholder {
    color:$danger;
  }
}


.invalid .invalid-feedback {
	display:block;
}

::selection {
  background: lighten($primary,30%); /* WebKit/Blink Browsers */
}
::-moz-selection {
  background: lighten($primary,30%); /* Gecko Browsers */
}

@include media-breakpoint-down(sm) {
	.btn {
		white-space:normal !important;
	}
}



.text-condensed {
	font-family: $font-family-condensed;
}
.text-ucase {
 text-transform: uppercase;
}



// number of cards per line for each breakpoint
$cards-per-line6: (
    xs: 2,
    sm: 2,
    md: 3,
    lg: 6,
    xl: 6
);
$cards-per-line5: (
    xs: 2,
    sm: 2,
    md: 3,
    lg: 5,
    xl: 5
);
$cards-per-line4: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 4,
    xl: 4
);
$cards-per-line3: (
    xs: 1,
    sm: 2,
    md: 3,
    lg: 3,
    xl: 3
);
$cards-per-line2: (
    xs: 1,
    sm: 2,
    md: 2,
    lg: 2,
    xl: 2
);
$cards-per-line1: (
    xs: 1,
    sm: 1,
    md: 1,
    lg: 1,
    xl: 1
);

@each $name, $breakpoint in $grid-breakpoints {
    @media (min-width: $breakpoint) {
				.block6 {
					.card-deck .card, &.card-deck .card {
						flex: 0 0 calc(#{100/map-get($cards-per-line6, $name)}% - #{$grid-gutter-width});
					}
				}
				.block5 {
          .card-deck .card, &.card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line5, $name)}% - #{$grid-gutter-width});
          }
        }
        .block4 {
					.card-deck .card, &.card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line4, $name)}% - #{$grid-gutter-width});
        	}
				}
				.block3 {
					.card-deck .card, &.card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line3, $name)}% - #{$grid-gutter-width});
        	}
				}
        .block2 {
					.card-deck .card, &.card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line2, $name)}% - #{$grid-gutter-width});
        	}
				}
        .block1 {
					.card-deck .card, &.card-deck .card {
            flex: 0 0 calc(#{100/map-get($cards-per-line1, $name)}% - #{$grid-gutter-width});
        	}
				}
    }
}
