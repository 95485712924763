.cover {
	height:100vh;
	min-height:500px;
	position: relative;
	overflow: hidden;

	@include media-breakpoint-down(sm) {
	height:170vh;
	min-height:1100px;
	}


	.img-right {
		position: absolute;
		bottom: 0px;
		left:5%;
		height:55%;
		z-index: 1;



		@include media-breakpoint-up(sm) {
		left:auto;
		right:-5%;
		height:55%;
		}
		@include media-breakpoint-up(md) {
		left:59%;
		height:90%;
		}
		@include media-breakpoint-up(lg) {
		right:-3%;
		left:auto;
		height:90%;
		}
	}
	.img-scroll {
		position: absolute;
		top: 98vh;
		left:50%;
		transform: translate(-50%,-100%);
		width:60px;
		z-index: 3;
	}
	.container{
		position: relative;
		z-index: 50;
		.logo {
			width:80%;
			max-width: 280px;
		}
		.link {
			display: inline-block;
			width:40%;
			img {
				width:100%;
			}
		}
	}

}
