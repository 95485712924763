// bootstrap/mixins
// --------------------------------------------------


//@import "elements/mixins/---";


@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}


@mixin prefix($map, $vendors: webkit moz ms o) {
  @each $prop, $value in $map {
    @if $vendors {
      @each $vendor in $vendors {
        #{"-" + $vendor + "-" + $prop}: #{$value};
      }
    }
    // Dump regular property anyway
    #{$prop}: #{$value};
  }
}


@include keyframes(passing-through) {

  0% {
    opacity: 0;
    @include prefix((transform: translateY(40px)));
  }

  30%, 70% {
    opacity: 1;
    @include prefix((transform: translateY(0px)));
  }

  100% {
    opacity: 0;
    @include prefix((transform: translateY(-40px)));
  }
}


@include keyframes(slide-in) {

  0% {
    opacity: 0;
    @include prefix((transform: translateY(40px)));
  }

  30% {
    opacity: 1;
    @include prefix((transform: translateY(0px)));
  }
}


@include keyframes(show-area) {

  0% {
    background-color: $gray-400;
  }
  100% {
    background-color: $white;
  }
}



@include keyframes(pulse) {

  0% { @include prefix((transform: scale(1))); }
  10% { @include prefix((transform: scale(1.1))); }
  20% { @include prefix((transform: scale(1))); }

}
